import { Box, Tab } from "@mui/material";

import { TabContext, TabList, TabPanel } from "@mui/lab";

import { useEffect, useRef, useState } from "react";

import { useLoaderData } from "react-router-dom";

import useTitle from "../hooks/useTitle";

import AutoTargetingTab from "../features/managed_plus_podcasts/AutoTargetingTab";
import SettingsTab from "../features/managed_plus_podcasts/SettingsTab";
import UrlsTab from "../features/managed_plus_podcasts/UrlsTab";

const SETTINGS_TAB = "1";
const URLS_TAB = "2";
const AUTO_TARGETING_TAB = "3";

const ManagedPlusPodcastCampaign = () => {
  const [currentTab, setTab] = useState(SETTINGS_TAB);
  const handleTabChange = (_event, currentTab) => setTab(currentTab);
  const { name } = useLoaderData();
  const componentRef = useRef(null);

  useEffect(() => {
    componentRef.current.parentElement.parentElement.scroll({
      top: 0,
    });
  }, []);

  useTitle(`M+ Campaigns > Podcast Campaigns > ${name}`);

  return (
    <Box sx={{ width: "100%" }} ref={componentRef}>
      <TabContext value={currentTab}>
        <TabList onChange={handleTabChange} aria-label="campaign tabs">
          <Tab label="CAMPAIGN SETTINGS" value={SETTINGS_TAB} />
          <Tab label="URLS" value={URLS_TAB} />
          <Tab label="AUTO TARGETING" value={AUTO_TARGETING_TAB} />
        </TabList>
        <TabPanel value={SETTINGS_TAB}>
          <SettingsTab />
        </TabPanel>
        <TabPanel value={URLS_TAB}>
          <UrlsTab />
        </TabPanel>
        <TabPanel value={AUTO_TARGETING_TAB}>
          <AutoTargetingTab />
        </TabPanel>
      </TabContext>
    </Box>
  );
};

export default ManagedPlusPodcastCampaign;
